import Axios from 'axios';

export const SubmitV2 = async (params) => {
	try {
		const result = await Axios({
			method: 'POST',
			url: '/api/submitv2',
			data: params,
		});
		return result;
	} catch (error) {
		throw new Error('Error submit v2 ', error);
	}
};

export const SubmitV1 = async (params) => {
	try {
		const result = await Axios({
			method: 'POST',
			url: '/api/submitv1',
			data: params,
		});
		return result;
	} catch (error) {
		throw new Error('Error submit v1 ', error);
	}
};

export const updateProfileFetch = async (params) => {
	try {
		const result = await Axios({
			method: 'PUT',
			url: '/api/updateProfile/',
			data: params,
		});
		return result;
	} catch (error) {
		throw new Error('Error Something', error);
	}
};

export const refreshCategoryStatus = async (params) => {
	try {
		const result = await Axios({
			method: 'GET',
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/bobaCategoryDetail?id=${params.data.category_id}`,
		});
		return result;
	} catch (err) {
		throw new Error('Error refresh category detail ', err);
	}
};

export const getBanner = async (params) => {
	try {
		const options = {
			method: 'GET',
			headers: {
				placement: params.placement,
				device: params.device,
				key: process.env.NEXT_PUBLIC_KEY_API,
			},
		};

		const response = await fetch(
			`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getBanner`,
			options,
		);
		const data = await response.json();

		return data;
	} catch (err) {
		throw new Error('Error fetch data banner', err);
	}
};

// this function is used for fetching data pages for-brands
export const fetchForBrands = async () => {
	try {
		const url = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/for-brands`;

		const options = {
			method: 'GET',
		};

		const response = await fetch(url, options);

		const result = await response.json();

		return result;
	} catch (error) {
		throw new Error('');
	}
};
