/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import Head from 'next/head';
import PropTypes from 'prop-types';
import NavbarHeader from '@fdn/navbar_header';
import { useCallback, useEffect, useState } from 'react';
import MegaMenu from '@fdn/mega_menu';
import AlloMain from '@fdn/allo_profile_migration';
import { useDispatch, useSelector } from 'react-redux';
import AlloExploreModal from 'Components/modal/alloExplore/AlloExploreModal';
import Env from 'Consts/env';
import {
	ScrollToUp,
	setDataLayer,
	setGTM,
	setGTMNoscript,
	setGoogleAnalytics,
	setGoogleAnalyticsNoscriptGaHost,
} from 'Helpers/utils';
import Cookies from 'js-cookie';
import { getBanner } from 'Helpers/fetch';
import { useRouter } from 'next/dist/client/router';
import AlloSectionProfilePopup from 'Components/allo/AlloProfilePopupSection';
import LoginModal from 'Components/modal/LoginModal';
import ButtonToTop from '@fdn/button_to_top';
import Vars from '../consts/vars';
import Footer from './footer';
import { loadCompleting } from '@/Store/completing/lib/actions';
// import { EnvironmentPlugin } from "loader-utils/node_modules/@types/webpack";

const baseAppName = 'Female Daily Best of Beauty Awards';
let appName = '';

export default function Layout({
	children,
	title,
	ismobile,
	self,
	// navMenu,
}) {
	if (typeof title !== 'undefined') {
		appName = `${baseAppName} - ${title}`;
	}

	const isMobile = ismobile || false;

	const dispatch = useDispatch();
	const getData = useSelector((state) => state);
	const router = useRouter();

	useCallback(() => {
		ScrollToUp();
	}, []);

	const [searchText, setSearchText] = useState('');
	const [isShowSearch, setShowSearch] = useState(false);
	const [isShowSearchResult, setShowSearchResult] = useState(false);
	const [isShowSearchInput, setShowSearchInput] = useState(false);
	const [isHidetopMenu, setToggleTopMenu] = useState(false);
	const [IsShowSidebar, setShowSidebar] = useState(false);
	const [isAlloExplorePopup, setIsAlloExplorePopup] = useState(false);
	const [IsShowAlloPopup, SetIsShowAlloPopup] = useState(false);
	const [ribbonBanner, setRibbonBanner] = useState([]);
	const [navHeight, setNavHeight] = useState(0);

	const updateSearchText = (e) => {
		setSearchText(e.target.value);

		if (
			(searchText.length >= 3 && !isMobile) ||
			(searchText.length > 0 && isMobile)
		) {
			setShowSearch(true);
			setShowSearchResult(true);
		} else {
			setShowSearch(false);
			setShowSearchResult(false);
		}
	};

	const submitSearch = (e) => {
		e.preventDefault();
		window.location.href = `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/search?q=${encodeURIComponent(searchText)}&tab=all`;
	};

	const showSearch = () => {
		if (!isMobile) {
			setShowSearch(true);
		} else {
			setShowSearchInput(true);
		}
	};

	const getRibbonBanner = () => {
		getBanner({
			placement: `${ismobile ? 'ribbon_banner_mobile' : 'ribbon_banner_desktop'}`,
		})
			.then((data) => {
				if (data?.data?.length > 0) {
					setRibbonBanner(() => ({
						image: data?.data[0]?.image,
						url: data?.data[0]?.url,
					}));
				}
			})
			.catch((err) => {
				console.log('Error Ribbon Banner', err);
			});
	};

	useEffect(() => {
		let interval;

		const dataLayerObj = {
			event: 'typing_keyword_on_search',
			username: getData.storeSelf?.data?.username || 'guest',
			is_login: !!getData.storeSelf?.data?.username,
			device: isMobile ? 'mobile' : 'desktop',
			keyword: searchText,
			referral: `fromglobalheaderfdboba${router.asPath.split('/')[1].replace(/[-]/g, '')}`,
		};

		if (
			(searchText.length >= 3 && !isMobile) ||
			(searchText.length > 0 && isMobile)
		) {
			interval = setTimeout(() => {
				setDataLayer(dataLayerObj, 'GTM');
			}, 1000);
		}

		return () => clearTimeout(interval);
	}, [searchText]);

	useEffect(() => {
		getRibbonBanner();

		if (!isMobile) {
			window.addEventListener('scroll', () => {
				if (document.documentElement.scrollTop > 84) {
					setToggleTopMenu(true);
				} else {
					setToggleTopMenu(false);
				}
			});
		}

		if (
			process.env.NEXT_PUBLIC_ALLO_STATUS === 'ON' &&
			getData.storeLoginPopup.isShowLoginPopup === false
		) {
			if (!Cookies.get('alloPopup') && !self.data.mpc_id) {
				// Allo Cookies check

				Cookies.set(
					'alloPopup',
					JSON.stringify({
						isShow: true,
						expiryTime: null,
					}),
					{
						domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'com' : 'net'}`,
					},
				);
				SetIsShowAlloPopup(true);
			} else if (Cookies.get('alloPopup') && self.data.mpc_id) {
				Cookies.remove('alloPopup', {
					domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'com' : 'net'}`,
				});
			} else {
				const checkAlloCookie = () => {
					try {
						JSON.parse(Cookies.get('alloPopup'));
					} catch (e) {
						return false;
					}
					return JSON.parse(Cookies.get('alloPopup'));
				};

				const alloPopupCookie = checkAlloCookie();

				if (typeof alloPopupCookie === 'object') {
					if (alloPopupCookie.isShow === true) {
						SetIsShowAlloPopup(true);
					} else if (
						alloPopupCookie.isShow === false &&
						alloPopupCookie.expiryTime < new Date().getTime()
					) {
						SetIsShowAlloPopup(true);
						Cookies.set(
							'alloPopup',
							JSON.stringify({
								isShow: true,
								expiryTime: null,
							}),
							{
								domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'com' : 'net'}`,
							},
						);
					}
				} else {
					Cookies.remove('alloPopup');
					Cookies.set(
						'alloPopup',
						JSON.stringify({
							isShow: true,
							expiryTime: null,
						}),
						{
							domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'com' : 'net'}`,
						},
					);
				}
			}
		}
	}, []);

	const handleAlloMenuNavbar = () => {
		if (isMobile) {
			setShowSidebar((state) => !state);
		}

		setIsAlloExplorePopup(true);
	};

	useEffect(() => {
		const placeholderNavbar = setTimeout(() => {
			const navbarHeight = document.getElementsByClassName('navbar-header')
				.length
				? document.getElementsByClassName('navbar-header')[0].offsetHeight
				: 0;
			setNavHeight(() => navbarHeight);
		}, 2000);

		return () => clearTimeout(placeholderNavbar);
	}, []);

	useEffect(() => {
		const placeholderNavbar = setTimeout(() => {
			const navbarHeight = document.getElementsByClassName('navbar-header')
				.length
				? document.getElementsByClassName('navbar-header')[0].offsetHeight
				: 0;
			setNavHeight(() => navbarHeight);
		}, 2000);

		return () => clearTimeout(placeholderNavbar);
	}, []);

	useEffect(() => {
		if (self?.data?.id) {
			dispatch(
				loadCompleting({
					payload: {
						api_env: process.env.NEXT_PUBLIC_NODE_ENV,
						headers: Env.HEADERS,
						Authorization: Cookies.get('token'),
					},
				}),
			);
		}
	}, []);
	const [userVerified, setUserVerified] = useState(null);
	const checkUserVerified = () => {
		const completingData = getData.storeCompleting.data;
		if (process.env.NEXT_PUBLIC_ALLO_STATUS === 'ON') {
			if (completingData?.data?.is_profile_complete) {
				return true;
			} else if (
				Array.isArray(completingData?.data?.account_status) &&
				completingData?.data?.account_status?.filter(
					(completion) =>
						completion?.description === 'complete_account_allo' &&
						completion?.status === true,
				).length
			) {
				return true;
			}

			return false;
		} else {
			const nonAlloAccountStatus = Array.isArray(
				completingData?.data?.account_status,
			)
				? completingData.data.account_status.filter(
						(completing) =>
							(completing?.description === 'complete_verify_email' ||
								completing?.description === 'complete_verify_phone') &&
							completing.status === true,
					)
				: [];

			return nonAlloAccountStatus.length === 2;
		}
	};
	useEffect(() => {
		if (getData.storeCompleting.status === 'fetch_result') {
			setUserVerified(checkUserVerified());
		}
	}, [getData.storeCompleting]);

	return (
		<div>
			<Head>
				<title>{appName}</title>
				<link rel="manifest" href="/manifest.json" />
				<link
					rel="icon"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/logo/favicon.ico"
				/>
				<meta charSet="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="144x144"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/logo/fdn_logo_144.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="114x114"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/logo/fdn_logo_114.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="72x72"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/logo/fdn_logo_72.png"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="57x57"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/logo/fdn_logo_57.png"
				/>
				<link
					rel="stylesheet"
					href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/font_google.css`}`}
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/icomoon/styles.css"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/new_icomoon/styles.css"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/fontastic_boba_fe/styles.css"
				/>

				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
				<link
					rel="stylesheet"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/style/bundle.css"
				/>
				<link
					rel="stylesheet"
					href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/boba/boba-bundle.css`}
				/>
				<link
					rel="stylesheet"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/fonts/creato_display.css"
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap"
					rel="stylesheet"
				/>

				{process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? (
					/* ALEXA */
					<script
						async
						custom-element="amp-analytics"
						src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
					/>
				) : null}

				{process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? (
					<script dangerouslySetInnerHTML={setGTM()} />
				) : null}

				{process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? (
					<script dangerouslySetInnerHTML={setGoogleAnalytics()} />
				) : (
					''
				)}

				{process.env.NEXT_PUBLIC_NODE_ENV !== 'production' ? (
					<meta name="robots" content="noindex" />
				) : null}
			</Head>

			{/* {process.env.NEXT_PUBLIC_NODE_ENV === "production" ? (
				<amp-analytics
					type="alexametrics"
					dangerouslySetInnerHTML={{
						__html: `
			<script type="application/json"> {"vars": { "atrk_acct": "5TYNs1O7kI20L7", "domain": "awards.femaledaily.com" }}</script>`,
					}}
				/>
			) : (
				""
			)} */}

			{process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? (
				<noscript dangerouslySetInnerHTML={setGTMNoscript()} />
			) : (
				''
			)}

			{process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? (
				<noscript
					dangerouslySetInnerHTML={setGoogleAnalyticsNoscriptGaHost()}
				/>
			) : (
				''
			)}

			<NavbarHeader
				is_mobile={isMobile}
				type="monochrome"
				self={self.data}
				nav_position="fixed"
				menu={Vars.top_menu}
				main_link={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
				home_link={process.env.NEXT_PUBLIC_ROOT_HOME}
				reviews_link={process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}
				talk_link={process.env.NEXT_PUBLIC_TALK_DOMAIN}
				sso_link={process.env.NEXT_PUBLIC_SSO_DOMAIN}
				// eslint-disable-next-line no-return-assign
				sidebar_config={Vars.config_sidebar}
				logo_id="id_logo_boba"
				search_input_id="id_search_input_field_boba"
				button_login_id="id_loginsignup_boba"
				button_cart_id="id_cartboba"
				button_notif_id="id_notifboba"
				submit_search={submitSearch}
				search_text={searchText}
				update_search_text={updateSearchText}
				eventImage={ribbonBanner?.image}
				eventURL={ribbonBanner?.url}
				close_search_suggest={() => {
					setSearchText('');
					setShowSearch(false);
				}}
				is_show_search_suggest={isShowSearch}
				is_show_search_result={isShowSearchResult}
				is_show_search={isShowSearchInput}
				show_search={showSearch}
				hide_search={() => {
					setShowSearch(false);
					setShowSearchInput(false);
				}}
				is_hide_top_menu={isHidetopMenu}
				articles={getData.storeSearchArticles.data}
				is_article_loading={
					getData.storeSearchArticles.status === 'fetch_loading'
				}
				brands={getData.storeSearchBrands.data}
				is_brand_loading={getData.storeSearchBrands.status === 'fetch_loading'}
				groups={getData.storeSearchGroups.data}
				is_group_loading={
					getData.storeSearchProducts.status === 'fetch_loading'
				}
				products={getData.storeSearchProducts.data}
				is_product_loading={
					getData.storeSearchProducts.status === 'fetch_loading'
				}
				users={getData.storeSearchUsers.data}
				is_user_loading={getData.storeSearchUsers.status === 'fetch_loading'}
				is_user_verified={userVerified}
				profile_link={
					self.data
						? `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/${self.data.username}`
						: null
				}
				setting_link={`${process.env.NEXT_PUBLIC_SSO_DOMAIN}/my/account`}
				onBlurInput={() => setTimeout(setShowSearch(false), 1000)}
				is_show_sidebar={IsShowSidebar}
				toggle_sidebar={() => setShowSidebar(!IsShowSidebar)}
				onClickLoginSignup={() => {
					if (process.env.NEXT_PUBLIC_ALLO_STATUS === 'ON') {
						if (isMobile) {
							SetIsShowAlloPopup(!IsShowAlloPopup);
							setShowSidebar(false);
						} else {
							SetIsShowAlloPopup(!IsShowAlloPopup);
						}
					} else {
						window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${window.location.href}`;
					}
				}}
				handleAlloExplorer={handleAlloMenuNavbar}
				profilePopupCustomElement={
					getData.storeSelf?.data?.mpc_id &&
					process.env.NEXT_PUBLIC_ALLO_STATUS === 'ON' && (
						<>
							<AlloSectionProfilePopup
								onClickAlloCoupon={handleAlloMenuNavbar}
							/>
						</>
					)
				}
				SisterSites={[
					{
						name: 'Mommies Daily',
						value: 'md',
						pathname: process.env.NEXT_PUBLIC_MD_DOMAIN,
						query: '',
						visible: true,
						new: false,
					},
					{
						name: 'Female Daily Studio',
						value: 'bs',
						pathname: process.env.NEXT_PUBLIC_ECM_DOMAIN,
						query: '',
						visible: true,
						new: false,
					},
					{
						name: 'Girls Beyond',
						value: 'gb',
						pathname: process.env.NEXT_PUBLIC_GB_DOMAIN,
						query: '',
						visible: true,
						new: false,
					},
				]}
			>
				<NavbarHeader.ChildrenWrapper>
					<div className="mega-menu-wrapper">
						<MegaMenu
							is_mobile={isMobile}
							align="left"
							menus={Vars.mega_menu}
						/>
					</div>
				</NavbarHeader.ChildrenWrapper>
			</NavbarHeader>

			<style>
				{`
					.mega-menu-wrapper .mega-menu .menus-wrapper {
						display: flex;
						justify-content: flex-start;
						margin-top: 13px;
					}
					img {
						max-width: 100%;
						max-height: 100%;
						width: auto;
						height: auto;
					}

					.navbar-header {
						background-color: #FFF;
					}
					.navbar-header-placeholder{
						height: ${navHeight}px;
					}
					.navbar-inner-content-wrapper {
						max-width: 1200px;
					}

					.top-menu {
						height: unset !important;
						max-height: 200px;
					}

					.top-menu.hidden {
						max-height: 0;
					}

					.mega-menu-wrapper {
						max-width: 1200px;
						margin: 0 auto;
						display: flex;
						justify-content: center;
					}

					.sub-menu-title a {
						text-transform: uppercase;
					}

					.menu-popup {
						max-width: calc(100% - 48px);
					}

					img.sub-menu-item {
						max-width: 100%;
					}

					.mega-menu .menus-wrapper a {
						transition: all .2s ease;
					}

					${
						isHidetopMenu && !isMobile
							? '.mega-menu .menus-wrapper a {font-size: 14px; margin-top: 10px; margin-bottom: 10px;}'
							: ''
					}

					.fdn-modal {
						top: 50% !important;
						left: 50% !important;
						margin-right: -50% !important;
						transform: translate(-50%, -50%) !important;
						margin: 0 !important;
						animation-name: unset !important;
						animation-duration: unset !important;
					}

          @media (max-width: 768px) {
						.navbar-header {
							z-index: 11;
						}

            .navbar-header .mega-menu {
              padding-bottom: 0;
            }

            .mega-menu-wrapper {
              width: 100%;
              max-width: 100%;
              margin-top: 12px;
              display: unset;
              justify-content: unset;
            }

            .menu-popup {
              max-width: unset;
            }

            .menu-popup-title {
              justify-content: space-between;
            }
          }

        `}
			</style>
			<style jsx global>
				{`
					html {
						box-sizing: border-box;
						scroll-behavior: smooth;
						margin: 0;
						height: 100%;
					}
					@font-face {
						font-family: 'Panamericana';
						src: url('${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/Panamericana-Display.woff') format('woff'),
							url('${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/Panamericana-Display.woff2') format('woff2');
					}

					* {
						box-sizing: inherit;
					}

					a {
						text-decoration: none;
					}

					p {
						margin: 0;
					}

					body {
						margin: 0px;
						max-width: 100%;
						overflow-x: hidden;
						font-family: Roboto;
					}

					.btn {
						background-color: transparent;
						border: 1px solid #FFF:
					}

					h1, h2, h3, h4, h5, h6 {
						font-family: "Josefin Sans";
						margin: 0;
					}

					.content-container {
						max-width: 1280px;
						padding: 0 15px;
						margin: 0 auto;
					}

					.section-title.underline::after {
						content: "";
						display: block;
						width: 90px;
						height: 6px;
						background-color: #000;
						margin-top: 27px;
					}

					.no-select {
						-webkit-tap-highlight-color: transparent;
						-webkit-touch-callout: none;
						-webkit-user-select: none;
						-khtml-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
				}

				.no-select:focus {
						outline: none !important;
				}

					@media only screen and (max-width: 991px) {
						.content-container {
							max-width: 500px;
						}

						.profile-popup {
							width: 100%;
						}
					}

					@media (max-width: 767px) {
						.section-title.underline::after {
							width: 45px;
							height: 3px;
							margin-top: 20px;
						}
					}
				`}
			</style>
			<div className="main-content">{children}</div>

			<AlloExploreModal
				isMobile={isMobile}
				isShow={isAlloExplorePopup}
				onClick={() => setIsAlloExplorePopup(false)}
			/>

			{IsShowAlloPopup && (
				<>
					<AlloMain
						isMobile={isMobile}
						query={{}}
						hostDomain={
							process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'com' : 'net'
						}
						ssoDomain={process.env.NEXT_PUBLIC_SSO_DOMAIN}
						// errorOffsetTop={state.navHeight + "px"}
						closeElement={() => SetIsShowAlloPopup(false)}
						// fetchMPC={(data) => FetchMPC(data)}
						// login={(data) => login(data)}
						// loginVerifyCode={(data) => login_verify_code(data)}
						// forgotPassword={(data) => forgot_password(data)}
						// registerCompleteAccount={(data) => register_complete_account(data)}
						onClickMigrateAccount={() => {
							const pageReferer = window.location.href;
							window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${pageReferer}`;
						}}
						onClickLoginRegister={() => {
							const pageReferer = window.location.href;
							window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${pageReferer}`;
						}}
						helpUrl={`${process.env.NEXT_PUBLIC_ROOT_HOME}/help?tab=account`}
					/>
					<style>
						{`
								.fdn-modal {
									animation: none !important;
								}

								@media (max-width: 767px) {

									.mpc-info-fd-logo-container img {
										width: unset;
										height: unset;
									}

								}
							`}
					</style>
				</>
			)}

			<LoginModal
				isMobile={isMobile}
				show={getData.storeLoginPopup?.isShowLoginPopup === true}
			/>

			<ButtonToTop isMobile={isMobile} />

			<Footer isMobile={isMobile} handleScrollUp={ScrollToUp} />
			<style>
				{`
					.footer-sosmed-list {
						width: 100%;
					}

					.footer-header1 {
						margin-top: 21px;
						margin-bottom: 10px;
					}
				`}
			</style>
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.node.isRequired,
	ismobile: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	self: PropTypes.objectOf(PropTypes.any).isRequired,
};

Layout.defaultProps = {
	ismobile: false,
};
