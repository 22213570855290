/* eslint-disable max-len */
import React from 'react';
import types from 'prop-types';
// import AlloBalanceText from "../AlloBalanceText";
// import AlloPointText from "../AlloPointText";
// import AlloCouponText from "../AlloCouponText";
import AlloPointTextNew from '../AlloPointTextNew';

const AlloSectionProfilePopup = ({ ...props }) => (
	<div className="profile-allo">
		<>
			{/* <AlloBalanceText
				onClickIcon={props.onClickAlloCoupon}
			/>
			<div style={{ borderLeft: "1px solid var(--fd-neutral-3)", borderRight: "1px solid var(--fd-neutral-3)" }}>
				<AlloPointText
					onClickIcon={props.onClickAlloCoupon}
				/>
			</div>
			<AlloCouponText
				coupon={0}
				onClickIcon={props.onClickAlloCoupon}
			/> */}
			<AlloPointTextNew onClickIcon={props.onClickAlloCoupon} />
		</>
		<style>
			{`
					.profile-allo {
						border-bottom: 1px solid #F0F3F7;
						// padding: 1rem 0.5rem;
						text-align: center;
						padding: 1.208rem 0.958rem;
						// display: grid;
						// grid-auto-columns: calc(100%/3);
						// grid-auto-flow: column;
					}

					@media (max-width: 767px) {

						.profile-allo {
							padding-bottom: 0;
							border: none;
						}

					}
				`}
		</style>
	</div>
);

export default AlloSectionProfilePopup;

AlloSectionProfilePopup.propTypes = {
	onClickAlloCoupon: types.func,
};

AlloSectionProfilePopup.defaultProps = {
	onClickAlloCoupon: () => null,
};
