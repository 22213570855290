import GlobalFooter from '@fdn/new_footer';
import PropTypes from 'prop-types';

export default function Footer({ isMobile, handleScrollUp }) {
	return (
		<GlobalFooter
			is_buttonToTop={false}
			is_mobile={isMobile}
			handleScrollUp={handleScrollUp}
			sosmedUrl={[
				{
					alt: 'logo-fb',
					src: 'https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-fb.svg',
					url: 'https://www.facebook.com/FemaleDailyNetwork/',
				},
				{
					alt: 'logo-twitter',
					src: 'https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-twitter.svg',
					url: 'https://twitter.com/femaledaily',
				},
				{
					alt: 'logo-ig',
					src: 'https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-ig.svg',
					url: 'https://www.instagram.com/femaledailynetwork/',
				},
				{
					alt: 'logo-youtube',
					src: 'https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-youtube.svg',
					url: 'https://www.youtube.com/user/FemaleDailyNetwork',
				},
				{
					alt: 'logo-tiktok',
					src: 'https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-tiktok.svg',
					url: 'https://www.tiktok.com/@femaledailynetwork',
				},
			]}
			footerMenu={[
				{
					title: 'About Us',
					target: '_self',
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/about`,
					id: 'id_aboutus',
				},
				{
					title: 'Terms & Conditions',
					target: '_self',
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/terms-and-conditions`,
					id: 'id_tc',
				},
				{
					title: 'Awards',
					target: '_self',
					href: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`,
					id: 'id_awards',
				},
				{
					title: 'Feedback',
					target: '_self',
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/feedback`,
					id: 'id_feedback',
				},
				{
					title: 'Privacy Policy',
					target: '_self',
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/privacy-policy`,
					id: 'id_privacy_policy',
				},
				// {
				// 	title: "Newsletter",
				// 	target: "_self",
				// 	href: `${process.env.NEXT_PUBLIC_MD_DOMAIN}`,
				// 	id: "id_newsletter",
				// },
				// {
				// 	title: "Contact",
				// 	target: "_self",
				// 	href: `${process.env.NEXT_PUBLIC_MD_DOMAIN}/contact`,
				// 	id: "id_contact",
				// },
				{
					title: 'Help',
					target: '_self',
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/help`,
					id: 'id_help',
				},
				{
					title: 'Media Kit',
					target: '_self',
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/media-kit`,
					id: 'id_media_kit',
				},
			]}
			footerSite={[
				{
					alt: 'logo-fd',
					src: 'https://image.femaledaily.com/web-assets/icon/logo-femaledaily.svg',
					url: `${process.env.NEXT_PUBLIC_ROOT_HOME}?ref=fromglobalfooter`,
					height: 28,
				},
				{
					alt: 'logo-md',
					src: 'https://image.femaledaily.com/web-assets/icon/logo-md.svg',
					url: `${process.env.NEXT_PUBLIC_MD_DOMAIN}?ref=fromglobalfooter`,
					height: 28,
				},
				{
					alt: 'logo-fds',
					src: 'https://image.femaledaily.com/web-assets/icon/logo-fds.svg',
					url: `${process.env.NEXT_PUBLIC_ECM_DOMAIN}?ref=fromglobalfooter`,
					height: 40,
				},
				{
					alt: 'logo-gb',
					src: 'https://image.femaledaily.com/web-assets/icon/logo-gb.svg',
					url: `${process.env.NEXT_PUBLIC_GB_DOMAIN}?ref=fromglobalfooter`,
					height: 36,
				},
			]}
		/>
	);
}

Footer.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	handleScrollUp: PropTypes.func.isRequired,
};
