import PropTypes from 'prop-types';
import Modal from '@fdn/modal';
import Button from '@fdn/button_new';

const LoginModal = ({ ...props }) => (
	<Modal show={props.show} element_id="login-modal">
		<h4 className={`fd-font-h4 mb-${props.isMobile ? '6' : '4'}`}>
			Session Expired
		</h4>
		<p className={`fd-font-body-lg-regular mb-${props.isMobile ? '6' : '4'}`}>
			Sesi Sudah Berakhir. Silahkan Login Kembali.
		</p>
		<Button
			variant="primary"
			elementId="btn-login-modal-action"
			customClass={`px-4 py-2 ${props.isMobile ? '' : 'mt-2'}`}
			onClick={props.onClick}
		>
			Login
		</Button>
		<style>
			{`
					#login-modal {
						border-radius: var(--radius-4);
						padding: ${props.isMobile ? 'var(--fd-space-5)' : 'var(--fd-space-6)'};
						max-width: ${props.isMobile ? 'calc(100% - 64px)' : '448px'};
						text-align: ${props.isMobile ? 'center' : 'left'};
						top: 50%;
						left: 50%;
						margin: 0;
						transform: translate(-50%, -50%);
						background: #FFF;
						border: none;
					}

					#login-modal .fdn-modal-header {
						display: none;
					}

					#btn-login-modal-action {
						display: block;
						margin-left: ${props.isMobile ? 'unset' : 'auto'};
						width: ${props.isMobile ? '100%' : 'fit-content'}
					}
				`}
		</style>
	</Modal>
);

export default LoginModal;

LoginModal.propTypes = {
	show: PropTypes.bool,
	isMobile: PropTypes.bool,
	onClick: PropTypes.func,
};

LoginModal.defaultProps = {
	show: false,
	isMobile: false,
	onClick: () => {
		const redirectURL = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${window ? window.location.href : process.env.NEXT_PUBLIC_ROOT_DOMAIN}`;
		setTimeout(() => {
			window.location.href = redirectURL;
		}, 300);
	},
};
