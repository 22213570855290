import React from 'react';

const AlloPointNewShimmer = () => (
	<div className="allo-shimmer">
		<div className="circle" />
		<div>
			<div className="bar" />
			<div className="bar" />
		</div>
		<style>
			{`
				.allo-shimmer {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.allo-shimmer .circle,
				.allo-shimmer .bar {
					animation-name: wave;
					animation-duration: 1s;
					animation-fill-mode: forwards;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					background-color: #f2f2f2;
					background-image: linear-gradient(
						125deg,
						#d8d8d8 20%,
						#f2f2f2 100%,
						#d8d8d8 50%
					);
					background-size: 50rem 10rem;
					box-sizing: border-box;
				}

				.allo-shimmer .circle {
					width: 33.33px;
					height: 33.33px;
					border-radius: 50%;
				}

				.allo-shimmer .bar {
					height: 14px;
					width: 120px;
					border-radius: 4px;
					margin-bottom: 4px;
				}
				
				.allo-shimmer .bar:nth-last-child(1) {
					margin-bottom: 0;
				}
				@keyframes wave {
					0% {
						background-position: -25rem 0;
					}
					100% {
						background-position: 25rem 0;
					}
				}
			`}
		</style>
	</div>
);

export default AlloPointNewShimmer;
