export default {
	FETCH_INIT: 'fetch_init',
	FETCH_LOADING: 'fetch_loading',
	FETCH_ERROR: 'fetch_error',
	FETCH_RESULT: 'fetch_result',
	top_menu: {
		title_menu: {
			text: 'Download Female Daily App',
			link: {
				desktop: '//app.femaledaily.com',
				mobile: {
					android:
						'https://play.google.com/store/apps/details?id=com.fdbr.android',
					ios: 'https://apps.apple.com/id/app/female-daily-beauty-review/id1160742672',
				},
			},
		},
		menus: [
			{
				text: 'FEMALE DAILY',
				link: process.env.NEXT_PUBLIC_ROOT_HOME,
				id: null,
				new: false,
			},
			{
				text: 'REVIEWS',
				link: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: 'ARTICLES',
				link: process.env.NEXT_PUBLIC_EDITORIAL_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: 'FEMALE DAILY STUDIO',
				link: process.env.NEXT_PUBLIC_ECM_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: 'TALK',
				link: process.env.NEXT_PUBLIC_TALK_DOMAIN,
				id: null,
				new: false,
			},
			{
				text: 'TRY AND REVIEW',
				link: `${process.env.NEXT_PUBLIC_ROOT_HOME}/try-and-review`,
				id: null,
				new: false,
			},
			{
				text:
					process.env.NEXT_PUBLIC_ALLO_STATUS === 'ON' ? 'ALLO EXPLORE' : null,
				id: null,
				new: false,
				image:
					process.env.NEXT_PUBLIC_ALLO_STATUS === 'ON'
						? 'https://image.femaledaily.com/web-assets/allo_explore_white.svg'
						: null,
			},
		],
	},
	mega_menu: [
		{
			name: 'HOME',
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}#`,
			slug: 'home',
			id: 'id_home_boba',
			children: [],
		},
		// {
		// 	name: "ABOUT",
		// 	url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}#about`,
		// 	slug: "about",
		// 	id: "id_about_boba",
		// 	children: [],
		// },
		// {
		// 	name: "WINNERS",
		// 	url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/winners`,
		// 	slug: "winners",
		// 	id: "id_winner_boba",
		// 	children: [],
		// // },
		// {
		// 	name: "SPONSOR",
		// 	url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}#sponsor`,
		// 	slug: "sponsor",
		// 	id: "id_sponsor_boba",
		// 	children: [],
		// },
		{
			name: 'ARTICLE',
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}#article`,
			slug: 'article',
			id: 'id_article_boba',
			children: [],
		},
		{
			name: 'MY VOTE',
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/my-vote`,
			slug: 'my-vote',
			id: 'id_myvote_boba',
			children: [],
		},
		{
			name: 'WINNERS',
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/winners`,
			slug: 'my-vote',
			id: 'id_winner_boba',
			children: [],
		},
		{
			name: 'FOR BRANDS',
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/for-brands`,
			slug: 'for-brands',
			id: 'id_for_brands',
			children: [],
		},
	],
	config_sidebar: [
		{
			name: 'REVIEWS',
			value: 'reviews',
			pathname: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
			query: 'order=newest',
			visible: true,
			new: false,
		},
		{
			name: 'ARTICLES',
			value: 'editorial',
			pathname: process.env.NEXT_PUBLIC_EDITORIAL_DOMAIN,
			query: '',
			visible: true,
			new: false,
		},
		{
			name: 'SHOP AT FEMALE DAILY STUDIO',
			value: 'studio',
			pathname: process.env.NEXT_PUBLIC_ECM_DOMAIN,
			query: 'page=1',
			visible: true,
			new: false,
		},
		{
			name: 'TALK',
			value: 'talk',
			pathname: process.env.NEXT_PUBLIC_TALK_DOMAIN,
			query: '',
			visible: true,
			new: false,
		},
		{
			name: 'TRY & REVIEW',
			value: 'try-and-review',
			pathname: `${process.env.NEXT_PUBLIC_ROOT_HOME}/try-and-review`,
			query: '',
			visible: true,
			new: false,
		},
	],
	COMPLETING_STEPS: [
		{
			id: 1,
			title: 'Phone Number',
			message: 'Verify your email for more secure account',
			img: 'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/phone.svg',
			img_active:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/phone-active.svg',
			img_finish:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/phone-finish.svg',
		},
		{
			id: 0,
			title: 'Email',
			message: 'Verify your phone number to keep using your account',
			img: 'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/email.svg',
			img_active:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/email-active.svg',
			img_finish:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/email-finish.svg',
		},
		{
			id: 2,
			title: 'Personal Info',
			message: 'Complete your profile and get 20XP',
			img: 'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/info.svg',
			img_active:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/info-active.svg',
			img_finish:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/info-finish.svg',
		},
		{
			id: 3,
			title: 'Beauty Profile',
			message: 'Complete your beauty profile and get 20XP',
			img: 'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/profile.svg',
			img_active:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/profile-active.svg',
			img_finish:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/profile-finish.svg',
		},
		{
			id: 4,
			title: 'Beauty Concern',
			message: 'Complete your beauty concern and get 20XP',
			img: 'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/concern.svg',
			img_active:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/concern-active.svg',
			img_finish:
				'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/completing-account-icon/concern-finish.svg',
		},
	],
	PERCENTAGE_SUBTITLE: [
		{
			percentage: '0%',
			subtitle: "Your beauty journey starts here. Let's complete your profile.",
		},
		{
			percentage: '25%',
			subtitle: "Way to start! Let's keep going.",
		},
		{
			percentage: '50%',
			subtitle: "Getting closer! You're halfway to completing your profile.",
		},
		{
			percentage: '75%',
			subtitle: "Few more steps and you're done! Let's go!",
		},
	],
};
