import moment from 'moment';
import PropTypes from 'prop-types';

export const imageSrc = ({ image }) => {
	const img = image ? image.split('://') : [];

	if (image) {
		if (img.length > 1) {
			return `//${img[1]}`;
		}

		return image;
	}

	return '//image.femaledaily.com/dyn/400/images/user-pics/placeholder_user.png';
};

export async function adsDetik(site, params) {
	try {
		const urls =
			'https://detikads.femaledaily.com/static/ads/femaledaily/staging_femaledaily/';
		const res = await fetch(`${urls + site}/${params}.txt`);

		return await res.text();
	} catch (error) {
		process.exit(1);
	}

	return null;
}

export function ScrollToUp() {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
}

export function ScrollToElement(elemenetID) {
	if (typeof window !== 'undefined') {
		const elem = document.getElementById(elemenetID).offsetTop;
		let header = 0;

		if (document.getElementsByClassName('navbar-header').length) {
			if (document.getElementsByClassName('top-menu').length) {
				header =
					document.getElementsByClassName('navbar-header')[0].offsetHeight -
					document.getElementsByClassName('top-menu')[0].offsetHeight;
			} else {
				header =
					document.getElementsByClassName('navbar-header')[0].offsetHeight;
			}
		}

		if (elem) window.scrollTo(0, elem - header);
	}
}

export const stringUrl = (params) => {
	const tmp = Array.isArray(params)
		? params[params.length - 1].replace(/[-]/g, ' ')
		: params.replace(/[-]/g, ' ');

	return tmp;
};

export const slugUrl = (params) => {
	const tmp = Array.isArray(params) ? params[params.length - 1] : params;

	return tmp;
};

export const categoryName = (slug, categories) => {
	const slugs = Array.isArray(slug) ? slug[slug.length - 1] : slug;

	const tmp = categories.filter(
		(item) => item.slug.replace(/[_]/g, '-') === slugs,
	)[0].name;

	return tmp;
};

export async function fetcher(...args) {
	const res = await fetch(...args);

	return res.json();
}

export const comSocre = () => ({
	__html: `
			var _comscore = _comscore || [];
			_comscore.push({ c1: "2", c2: "8443234" });
			(function() {
			var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
			s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
			el.parentNode.insertBefore(s, el);
			})();
		`,
});

export const setGoogleAnalytics = () => {
	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;

	return {
		__html: `
		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
		})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

		ga('create', '${GaGeneral}', 'auto', 'fdn');
		ga('fdn.send', 'pageview');
		`,
	};
};

export const setGoogleAnalyticsNoscript = () => ({
	__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GA_GENERAL_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
});

export const setGoogleAnalyticsNoscriptGaHost = () => ({
	__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GA_HOST_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
});

export const momentDate = ({ date }) => {
	const localDate = moment(moment.utc(date).toDate())
		.local()
		.format('YYYY-MM-DD HH:mm:ss');
	const res = moment(localDate).add(7, 'days').isBefore(moment())
		? moment(localDate).format('DD MMM YYYY')
		: moment(localDate).fromNow();
	return res;
};

export const setGTM = () => ({
	__html: `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
		`,
});

export const setGTMNoscript = () => ({
	__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
});

export const Rupiah = (props) => {
	const reverse = props.toString().split('').reverse().join('');
	const ribuan = reverse.match(/\d{1,3}/g);
	const final = ribuan.join('.').split('').reverse().join('');

	return `Rp. ${final}`;
};

export const ThousandSeparator = (props) => {
	const reverse = props.toString().split('').reverse().join('');
	const ribuan = reverse.match(/\d{1,3}/g);
	const final = ribuan.join('.').split('').reverse().join('');

	return final;
};

export const setDataLayer = (dataObj, urlTarget) => {
	if (typeof window === 'undefined') return;
	const dataLayer = window.dataLayer || undefined;
	if (typeof dataLayer === 'undefined') return;
	if (urlTarget === 'GTM') {
		const dataLayer = window.dataLayer || [];

		if (typeof dataObj === 'object' && dataObj !== null) {
			dataLayer.push(dataObj);
		}
	} else {
		// eslint-disable-next-line consistent-return
		return {
			viewPopupCompleteProfile: (dataLayerParams) => {
				dataLayer.push({
					event: 'view_popup_complete_profile',
					...dataLayerParams,
				});
			},
		};
	}
};

export const arrayChecker = (payload) => {
	if (Array.isArray(payload)) {
		return payload;
	} else {
		return [];
	}
};

export const getStoreSelfPropTypes = PropTypes.shape({
	data: {
		id: PropTypes.number,
		usrapo_id: PropTypes.number,
		user_id: PropTypes.number,
		fullname: PropTypes.string,
		username: PropTypes.string,
		about: PropTypes.string,
		follower_count: PropTypes.number,
		following_count: PropTypes.number,
		total_review: PropTypes.number,
		total_post: PropTypes.number,
		total_point: PropTypes.number,
		total_badge: PropTypes.number,
		bio: PropTypes.string,
		gender: PropTypes.string,
		picture: PropTypes.shape({
			xtra_small: PropTypes.string,
			small: PropTypes.string,
			medium: PropTypes.string,
			large: PropTypes.string,
		}),
		beauty_profile: PropTypes.shape({
			skin_type_id: PropTypes.number,
			skin_type_name: PropTypes.string,
			skin_tone_id: PropTypes.number,
			skin_tone_name: PropTypes.string,
			skin_undertone_id: PropTypes.number,
			skin_undertone_name: PropTypes.string,
			skin_hairtype_id: PropTypes.number,
			skin_hairtype_name: PropTypes.string,
			hair_texture_id: PropTypes.number,
			hair_texture_name: PropTypes.string,
			is_hijab: PropTypes.number,
			hair_color: PropTypes.number,
		}),
		beauty_concern: PropTypes.shape({
			skin: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string,
					img: PropTypes.string,
				}),
			),
			hair: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string,
					img: PropTypes.string,
				}),
			),
			body: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string,
					img: PropTypes.string,
				}),
			),
		}),
		is_beauty_concern_complete: PropTypes.string,
		brands: PropTypes.array,
		location: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
		}),
		is_follow: PropTypes.bool,
		is_facebook_connect: PropTypes.bool,
		age_range: PropTypes.string,
		completeness: PropTypes.number,
		badges: PropTypes.array,
		is_beauty_panelist: PropTypes.bool,
		apps_rating: PropTypes.number,
		apps_review: PropTypes.string,
		code: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
				campaign_id: PropTypes.number,
				code: PropTypes.string,
			}),
		),
		level: PropTypes.string,
		level_image: PropTypes.string,
		User_pics: PropTypes.arrayOf(
			PropTypes.shape({
				usrpic_id: PropTypes.number,
				usrpic_usrapo_id: PropTypes.number,
				usrpic_userpics_large: PropTypes.string,
			}),
		),
		total_wishlist: PropTypes.number,
		total_tried: PropTypes.number,
		is_completed_elastic: PropTypes.bool,
		is_verified: PropTypes.bool,
		instagram: PropTypes.string,
		blog: PropTypes.string,
		youtube: PropTypes.string,
		facebook: PropTypes.string,
		twitter: PropTypes.string,
	},
	message: PropTypes.string,
	status: PropTypes.string,
});

export const errorImageFallback = (event, size = 200) => {
	const { target } = event;
	const url = process.env.NEXT_PUBLIC_IMAGE_ERROR;
	const dynUrl = url.replace(/(\/error-image\/)/, `/dyn/${size}$1`);
	target.src = dynUrl;
	target.srcset = dynUrl;
};

export const defaultImage = (size = 200) => {
	const url = process.env.NEXT_PUBLIC_IMAGE_EMPTY;
	const dynUrl = url.replace(/(\/error-image\/)/, `/dyn/${size}$1`);
	return dynUrl;
};
